import { ResponsiveFunnel } from '@nivo/funnel'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { ContentInsightsCompletionRates } from '../../../graphQL/content_insights'

import {
  contentInsightsCompletionRates,
  contentInsightsCompletionRatesVariables,
} from '../../../__generated__/contentInsightsCompletionRates'

export const ContentInsightsCompletionRateFunnel = ({
  recordId,
}: {
  recordId: string
}) => {
  const completionRateFunnelDataResult = useQuery<
    contentInsightsCompletionRates,
    contentInsightsCompletionRatesVariables
  >(ContentInsightsCompletionRates, {
    variables: {
      id: recordId,
    },
  })

  const completionRateFunnelData =
    completionRateFunnelDataResult.data?.qry?.nodes?.map((item: any) => {
      return {
        id: item.label,
        label: item.label,
        value: item.count,
      }
    }) || []

  return (
    <ErrorBoundary fallbackRender={() => <div>Error rendering chart</div>}>
      {completionRateFunnelData.length > 0 ? (
        <ResponsiveFunnel
          data={completionRateFunnelData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          colors={{ scheme: 'spectral' }}
          borderWidth={20}
          labelColor={{
            from: 'color',
            modifiers: [['darker', 3]],
          }}
          beforeSeparatorLength={10}
          beforeSeparatorOffset={20}
          afterSeparatorLength={10}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      ) : null}
    </ErrorBoundary>
  )
}
