import { Tooltip } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  ChipField,
  DateField,
  FieldProps,
  FunctionField,
  NumberField,
  Record,
  ReferenceField,
  ReferenceFieldProps,
  TextField,
} from 'react-admin'
import { FieldTraverser } from '../dataProvider/introspections/SchemaTraverser'
import { SMPL_CUSTOMER, isNetzkinoCustomer } from '../lib/config'
import { Token } from '../utils/Token'
import { fallbackSources } from './GenericDataList'
import {
  SMPL_TEMP_CATEGORIES,
  SMPL_TEMP_CATEGORIES_FOR_REMOVAL,
} from './GenericEditPage'
import { CUSTOM_FIELD_COMPONENTS } from './customFields'
import { CustomDataFields } from './customFields/CustomDataFields'
import { CustomImageField } from './customFields/CustomImageField'
import { FallbackSourceField } from './customFields/FallbackSourceField'
import { FlagsFields } from './customFields/FlagsFields'
import { JsonField } from './customFields/JsonField'
import { ListFields } from './customFields/ListFields'
import { MaxLengthTextField } from './customFields/MaxLengthTextField'
import { MaxLengthUrlField } from './customFields/MaxLengthUrlField'
import { NoData } from './customFields/NoData'
import { ObjectFields } from './customFields/ObjectFields'
import { ShowTypeReferenceField } from './customFields/ShowTypeReferenceField'
import {
  TypeDisplaySetting,
  defaultListShowEditAndCreate,
} from './settings/TypeDisplaySetting'

const useStyles = makeStyles({
  imageField: {
    backgroundColor: 'lightgray',
    borderRadius: '0.5em',
    '& > img': {
      display: 'block',
      margin: 'auto',
      padding: 10,
    },
  },
  chip: { margin: 2 },
  fullGridWidthElement: {
    gridColumnStart: 1,
    gridColumnEnd: 'col1-start',
  },
})

export function renderCustomField(
  resource: string,
  source: string,
  fieldProps: FieldProps,
  renderType?: RenderType
) {
  const classes = useStyles()
  const customTypeDef = TypeDisplaySetting[resource]
  if (
    customTypeDef &&
    (customTypeDef.show.fields[source] || customTypeDef.list.fields[source])
  ) {
    const fieldDefinition = customTypeDef.show.fields[source]
      ? customTypeDef.show.fields[source]
      : customTypeDef.list.fields[source]
    if (fieldDefinition && fieldDefinition.type) {
      const FieldComponent = CUSTOM_FIELD_COMPONENTS[fieldDefinition.type]

      let style = undefined

      switch (fieldDefinition.type) {
        case 'CAT_ALLOCATION':
          style = classes.fullGridWidthElement
          break
        default:
          break
      }

      if (FieldComponent) {
        return (
          <FieldComponent
            className={style}
            addLabel={true}
            source={source}
            key={source}
            // @ts-ignore
            renderType={renderType}
            // @ts-ignore
            {...fieldProps}
            {...(fieldDefinition.props || null)}
          />
        )
      }
      console.error(
        'could not render field "%s". Unknown field type:',
        source,
        fieldDefinition.type
      )
    }
  }
  console.error(
    'Nothing to render for field "%s". Field seems to be a custom field, but is not defined in TypeDisplaySettings.',
    source
  )
  return null
}

type RenderType = 'show' | 'list'

/**
 * Rendering of non id fields
 */
export const ScalarFieldRenderer = React.memo(function ScalarFieldRendererImpl({
  field,
  renderType,
  ...fieldProps
}: FieldProps & { field: FieldTraverser; renderType: RenderType }) {
  const classes = useStyles()
  const { name, typeName } = field

  const { source, record } = fieldProps
  const resource = field.resource.name

  if (!source) {
    return null
  }
  const val = record?.[source]
  if (val === null || val === undefined) {
    return <NoData />
  }
  if (field.valueKind === 'LIST' && !val?.length) {
    return <NoData />
  }
  if (field.isEnum) {
    return <ChipField {...fieldProps} addLabel />
  }

  // use this to replace existing regular field with custom field
  // -> Input in TypeDisplaySetting with regular field name
  /**
   *  @params
   *  resource == 'CmsMovie' || 'Subscription' etc.
   *  renderType == 'list' || 'show'
   *  name == 'createdDate' || 'title' etc.
   */
  const typeDisplaySettings =
    TypeDisplaySetting[resource] !== undefined
      ? TypeDisplaySetting[resource]![renderType].fields[name]
      : defaultListShowEditAndCreate[renderType].fields[name]

  if (typeDisplaySettings) {
    const typeDisplaySettingNameType = typeDisplaySettings.type
    // switching through the type standing in TypeDisplaySetting (which later coming from a database)
    // instead of the type standing in field: FieldTraverser, gives better possibilities of styling/customizing

    if (typeDisplaySettingNameType === 'ISACTIVE') {
      if (SMPL_CUSTOMER === 'universum-dev' || SMPL_CUSTOMER === 'universum') {
        // this will render isActiveField which is currently only useful for universum
        return renderCustomField(resource, source, fieldProps, renderType)
      }
    }

    switch (typeDisplaySettingNameType) {
      /**
       * @return Url Field with start ellipsis and a preview of the picture on hover
       * @important for using with Picture Url only
       */
      case 'URL_FIELD_POPUP': {
        // Url TextField that show the end of the Url e.g. '...textPic.svg'
        // no endAdornment but with Pop-Up preview of picture behind the Url
        // for using with Picture only
        return (
          <MaxLengthTextField
            singleLine={true}
            shortenUrl={true}
            popUpPic={true}
            typeDisplaySettings={typeDisplaySettings}
            {...fieldProps}
          />
        )
      }
      /**
       * @return Url Field with endAdornment (copy and open) and a preview of the picture behind the Url below
       * @important for using with Picture Url only
       */
      case 'URL_FIELD_PIC': {
        // Url Field with endAdornment (copy and open) and a preview of the picture behind the Url below
        // for using with Picture only
        return (
          <span>
            <MaxLengthUrlField
              {...(typeDisplaySettings.props || null)}
              {...fieldProps}
            />
            <CustomImageField {...fieldProps} />
          </span>
        )
      }
      case 'URL_FIELD': {
        return (
          <MaxLengthUrlField
            {...(typeDisplaySettings.props || null)}
            {...fieldProps}
          />
        )
      }
      case 'TEXT_FIELD': {
        // Simple Text Field
        return (
          <TextField {...(typeDisplaySettings.props || null)} {...fieldProps} />
        )
      }
      case 'FLAGS_FIELD': {
        const flags: string[] = record?.[source]
        return (
          // @ts-ignore
          <FlagsFields flags={flags} {...(typeDisplaySettings.props || null)} />
        )
      }
      case 'CHIP_FIELD':
        const fieldValue = record?.[source]
        if (!fieldValue) return <NoData />
        const {
          inputProps: { separator },
        } = typeDisplaySettings.props as {
          inputProps: {
            separator: string
          }
        }
        if (!separator) {
          console.error(
            `CHIP_FIELD for ${name} wasn't provided with a separator`
          )
          return (
            <MaxLengthTextField
              ignoreMaxLength={renderType === 'show'}
              singleLine={renderType === 'list'}
              {...fieldProps}
            />
          )
        }
        const valuesArray = (fieldValue as string).split(separator)
        return (
          <div>
            {valuesArray &&
              valuesArray.map((value: any) => {
                return (
                  <Chip
                    key={value}
                    label={value}
                    color="primary"
                    className={classes.chip}
                  />
                )
              })}
          </div>
        )
      case 'CUSTOM_DATA_FIELD': {
        return (
          // @ts-ignore
          <CustomDataFields
            record={record?.[source] ? JSON.parse(record[source]) : undefined}
            {...(typeDisplaySettings.props || null)}
          />
        )
      }
      default: {
        // not a graphql type, maybe a custom field
      }
    }
  }

  // use this to add new field without replacing regular field
  // -> Input in TabLayoutSetting and TypeLayoutSetting in form of 'custom:xyz'
  if (source.includes(':')) {
    // regular fields never have : in their name!
    const renderedCustomField = renderCustomField(resource, source, fieldProps)
    if (renderedCustomField) {
      return renderedCustomField
    }
  }

  if (field.valueKind === 'OBJECT') {
    return <ObjectFields {...fieldProps} />
  }
  // List on show
  if (field.valueKind === 'LIST' && renderType === 'show') {
    // SMPL-413 in case we have at least one non-object in the list, we want to show chips for all non-objects in the list
    let valuesArray
    let color: 'primary' | 'secondary' = 'primary'

    switch (name) {
      case SMPL_TEMP_CATEGORIES:
        valuesArray = record?.[source].map(
          (e: Record) => e.title || e.slug || e.externalIdentifier
        )
        // color = 'primary'
        break
      case SMPL_TEMP_CATEGORIES_FOR_REMOVAL:
        valuesArray = record?.[source].map(
          (e: Record) => e.title || e.slug || e.externalIdentifier
        )
        // color = 'secondary'
        break
      default:
        valuesArray = record?.[source].filter((v: any) => typeof v !== 'object')
        break
    }

    if (valuesArray.length > 0) {
      // render non-object lists as Chips
      return (
        <div>
          {valuesArray &&
            valuesArray.map((value: any) => {
              if (
                isNetzkinoCustomer &&
                value?.toString()?.toLocaleLowerCase()?.includes('youtube')
              ) {
                color = 'secondary' // red
              }
              return (
                <Chip
                  key={value}
                  label={value}
                  color={color}
                  className={classes.chip}
                />
              )
            })}
        </div>
      )
    }

    // otherwise show object values as Accordion elements
    return <ListFields {...fieldProps} />
  }

  // List on list
  if (field.valueKind === 'LIST' && renderType === 'list') {
    if (typeName === 'String') {
      const valArray: string[] = record?.[source]
      if (valArray) {
        return (
          <Tooltip title={valArray.join(', ')}>
            <Typography
              className="listItemEllipsis"
              component="span"
              variant="body2"
            >
              {valArray.join(', ')}
            </Typography>
          </Tooltip>
        )
      }
    }
    return (
      <Token color="primary" variant="light">
        OBJECT
      </Token>
    )
  }

  switch (typeName) {
    case 'Date': {
      return <DateField {...fieldProps} />
    }
    case 'Datetime': {
      return <DateField {...fieldProps} showTime />
    }
    case 'Boolean': {
      return <BooleanField {...fieldProps} />
    }
    case 'Guid': {
      return (
        <MaxLengthTextField
          ignoreMaxLength={renderType === 'show'}
          singleLine
          {...fieldProps}
        />
      )
    }
    case 'ID': {
      // nodeId
      return null
    }
    case 'String': {
      return (
        <MaxLengthTextField
          ignoreMaxLength={renderType === 'show'}
          singleLine={renderType === 'list'}
          {...fieldProps}
        />
      )
    }
    case 'Float': // falls-through
    case 'BigFloat': // falls-through
    case 'Int': {
      // nodeId
      // for numbers, we want to show them with the maximum of decimal points
      const numberfieldProps = {
        options: {
          maximumFractionDigits: 20,
        },
        ...fieldProps,
      }
      return <NumberField {...numberfieldProps} />
    }
    case 'JSON': {
      return <JsonField {...fieldProps} showContent={renderType === 'show'} />
    }
    default: {
    }
  }
  return null
})

export function ReferenceFieldNoData(
  props: ReferenceFieldProps & {
    renderType: 'show' | 'list'
    allowEmpty: Boolean
  }
) {
  const { record, source, reference, renderType } = props
  const val = record?.[source]
  if (val === null) {
    return <NoData />
  }

  if (renderType === 'show' && reference === 'CmsImage') {
    return <CustomImageField {...props} />
  }

  return (
    <ReferenceField
      {...props}
      link={() => `/${reference}/${val}/show`}
      // @ts-ignore
      originalReference={val}
    />
  )
}

export const RenderScalarField = (
  props: { field: FieldTraverser } & {
    renderType: RenderType
  } & FieldProps
) => {
  const { record, field, renderType, ...commonProps } = props
  return (
    <ScalarFieldRenderer
      record={record}
      field={field}
      renderType={renderType}
      {...commonProps}
    />
  )
}

export const camelCaseToText = (camelCaseWord: string) =>
  camelCaseWord
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function (str) {
      return str.toUpperCase()
    })

export function truncateString(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

export function renderField(field: FieldTraverser, page: RenderType) {
  if (
    field.isInternal ||
    (page === 'show' && field.isHiddenOnShow) ||
    (page === 'list' && field.isHiddenInList)
  ) {
    return null
  }
  const resource = field.resource.name

  const propsFromSettingsForEdit =
    TypeDisplaySetting?.[resource]?.editAndCreate?.fields?.[field.name]?.props

  const propsFromSettingsForDisplay =
    TypeDisplaySetting?.[resource]?.[page]?.fields?.[field.name]?.props

  const fieldLabel =
    // @ts-expect-error
    propsFromSettingsForDisplay?.label ??
    propsFromSettingsForEdit?.label ??
    camelCaseToText(field.name)

  const commonProps = {
    source: field.name,
    addLabel: true,
    label: fieldLabel,
    isSortable: !field.isList && !field.isObject,
    ...(propsFromSettingsForDisplay ?? {}),
  }

  // field.field.type?.kind === 'LIST' && field.field.type?.ofType.kind === 'OBJECT' === 'true', we don't want to show all of them only some
  const allowedListObjectField = [
    'dashAudioInfo',
    'dashTextTrackInfo',
    'hlsAudioInfo',
    'hlsTextTrackInfo',
  ]

  if (
    allowedListObjectField.includes(field.name) ||
    field.isScalar ||
    field.isEnum ||
    field.isList ||
    'OBJECT' == field.valueKind
  ) {
    const { referenceTypeName } = field
    if (referenceTypeName) {
      return (
        <ReferenceFieldNoData
          key={field.name}
          {...commonProps}
          reference={referenceTypeName}
          addLabel
          allowEmpty
          renderType={page}
          //link="show"
        >
          <FallbackSourceField
            sources={fallbackSources}
            Field={ShowTypeReferenceField}
            tooltip={referenceTypeName}
            fixedWidth
          />
        </ReferenceFieldNoData>
      )
    }

    return (
      <FunctionField
        key={field.name}
        {...commonProps}
        render={(record: any) => {
          return (
            <RenderScalarField
              record={record}
              field={field}
              renderType={page}
              {...commonProps}
            />
          )
        }}
      />
    )
  }
  // possibly a reference
  return null
}
