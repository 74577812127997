import { ResponsiveLine } from '@nivo/line'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import {
  ContentInsightsDownloadEvents,
  ContentInsightsPlayEvents,
  ContentInsightsWishlistEntries,
} from '../../../graphQL/content_insights'

import {
  contentInsightsDownloadEvents,
  contentInsightsDownloadEventsVariables,
} from '../../../__generated__/contentInsightsDownloadEvents'
import {
  contentInsightsPlayEvents,
  contentInsightsPlayEventsVariables,
} from '../../../__generated__/contentInsightsPlayEvents'
import {
  contentInsightsWishlistEntries,
  contentInsightsWishlistEntriesVariables,
} from '../../../__generated__/contentInsightsWishlistEntries'

export const ContentInsightsTrendDataChart = ({
  recordId,
}: {
  recordId: string
}) => {
  const trendsDataPlayEventsResult = useQuery<
    contentInsightsPlayEvents,
    contentInsightsPlayEventsVariables
  >(ContentInsightsPlayEvents, {
    variables: {
      id: recordId,
      dateInterval: { days: 14 },
    },
  })
  const trendsDataPlayEvents =
    trendsDataPlayEventsResult.data?.qry?.nodes?.map((item: any) => {
      return {
        x: item.date,
        y: item.count,
      }
    }) || []

  const trendsDataDownloadEventsResult = useQuery<
    contentInsightsDownloadEvents,
    contentInsightsDownloadEventsVariables
  >(ContentInsightsDownloadEvents, {
    variables: {
      id: recordId,
      dateInterval: { days: 14 },
    },
  })
  const trendsDataDownloadEvents =
    trendsDataDownloadEventsResult.data?.qry?.nodes?.map((item: any) => {
      return {
        x: item.date,
        y: item.count,
      }
    }) || []

  const trendsDataWishlistEntriesResult = useQuery<
    contentInsightsWishlistEntries,
    contentInsightsWishlistEntriesVariables
  >(ContentInsightsWishlistEntries, {
    variables: {
      id: recordId,
      dateInterval: { days: 14 },
    },
  })
  const trendsDataWishlistEntries =
    trendsDataWishlistEntriesResult.data?.qry?.nodes?.map((item: any) => {
      return {
        x: item.date,
        y: item.count,
      }
    }) || []

  return (
    <ErrorBoundary fallbackRender={() => <div>Error rendering chart</div>}>
      {trendsDataPlayEvents.length > 0 &&
      trendsDataDownloadEvents.length > 0 &&
      trendsDataWishlistEntries.length > 0 ? (
        <ResponsiveLine
          data={[
            {
              id: 'Plays',
              data: trendsDataPlayEvents,
            },
            {
              id: 'Downloads',
              data: trendsDataDownloadEvents,
            },
            {
              id: 'Wishlist',
              data: trendsDataWishlistEntries,
            },
          ]}
          margin={{ top: 30, right: 100, bottom: 30, left: 50 }}
          xScale={{
            format: '%Y-%m-%dT%H:%M:%S+00:00',
            precision: 'day',
            type: 'time',
            useUTC: false,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
          }}
          axisBottom={{
            format: '%b',
            tickValues: 'every 30 days',
          }}
          axisLeft={{
            legend: '',
          }}
          enableSlices="x"
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          useMesh
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              translateX: 100,
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
            },
          ]}
        />
      ) : null}
    </ErrorBoundary>
  )
}
