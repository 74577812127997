import { ResponsiveLine } from '@nivo/line'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { ContentInsightsWatchduration } from '../../../graphQL/content_insights'

import {
  contentInsightsWatchduration,
  contentInsightsWatchdurationVariables,
} from '../../../__generated__/contentInsightsWatchduration'

export const ContentInsightsWatchdurationChart = ({
  recordId,
  chartStartDate,
}: {
  recordId: string
  chartStartDate: Date
}) => {
  chartStartDate.setHours(0, 0, 0, 0)
  const approxDiffDays = Math.round(
    Math.abs(new Date().getTime() - chartStartDate.getTime()) /
      1000 /
      60 /
      60 /
      24
  )
  const trendsDataWatchduration = useQuery<
    contentInsightsWatchduration,
    contentInsightsWatchdurationVariables
  >(ContentInsightsWatchduration, {
    variables: {
      id: recordId,
      dateInterval: {
        days:
          approxDiffDays > 720
            ? 90
            : approxDiffDays > 365
            ? 30
            : approxDiffDays > 180
            ? 14
            : approxDiffDays > 90
            ? 7
            : 1,
      },
      dateFrom: chartStartDate.toDateString(),
    },
  })

  const watchdurationChartData =
    trendsDataWatchduration.data?.qry?.nodes?.map((item: any) => {
      return {
        x: item.date,
        y: Math.round((item.count / 60 / 60) * 100) / 100,
      }
    }) || []

  return (
    <ErrorBoundary fallbackRender={() => <div>Error rendering chart</div>}>
      {watchdurationChartData.length > 0 ? (
        <ResponsiveLine
          data={[
            {
              id: 'Watchduration',
              data: watchdurationChartData,
            },
          ]}
          margin={{ top: 30, right: 50, bottom: 50, left: 50 }}
          xScale={{
            format: '%Y-%m-%dT%H:%M:%S+00:00',
            precision: 'day',
            type: 'time',
            useUTC: true,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
          }}
          axisBottom={{
            format: '%b',
            tickValues: 'every 30 days',
          }}
          axisLeft={{
            legend: '',
          }}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return `from previous until ${slice.points[0].data.xFormatted}: ${slice.points[0].data.yFormatted}h`
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          useMesh
        />
      ) : null}
    </ErrorBoundary>
  )
}
