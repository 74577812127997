import { ResponsivePie } from '@nivo/pie'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { ContentInsightsPlayEventsByPlatform } from '../../../graphQL/content_insights'

import {
  contentInsightsPlayEventsByPlatform,
  contentInsightsPlayEventsByPlatformVariables,
} from '../../../__generated__/contentInsightsPlayEventsByPlatform'

export const ContentInsightsPlayEventsByPlatformChart = ({
  recordId,
}: {
  recordId: string
}) => {
  const playEventsByPlatformResult = useQuery<
    contentInsightsPlayEventsByPlatform,
    contentInsightsPlayEventsByPlatformVariables
  >(ContentInsightsPlayEventsByPlatform, {
    variables: {
      id: recordId,
    },
  })

  const playEventsByPlatform =
    playEventsByPlatformResult.data?.qry?.nodes.map((item) => {
      return {
        id: item.platform,
        label: item.platform,
        value: item.count,
      }
    }) || []

  return (
    <ErrorBoundary fallbackRender={() => <div>Error rendering chart</div>}>
      {playEventsByPlatform.length > 0 ? (
        <ResponsivePie
          data={playEventsByPlatform}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          innerRadius={0.4}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
        />
      ) : null}
    </ErrorBoundary>
  )
}
