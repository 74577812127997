import {
  Button,
  Container,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { Record, useNotify } from 'react-admin'
import { useMutation } from 'react-apollo-hooks'
import {
  KycReasonLog,
  KycReasonLogVariables,
} from '../../__generated__/flexgold/kycReasonLog'
import { kycReasonLogMutation } from '../../graphQL/flexgold/kycReasonLog'

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
}))

export type KycStatusUpdateProps = {
  data: Record
}

const KycStatusUpdateForm = (props: KycStatusUpdateProps) => {
  const { data } = props
  const classes = useStyles()
  const notify = useNotify()
  const [reason, setReason] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const executeKycStatusUpdateMutation = useMutation<
    KycReasonLog,
    KycReasonLogVariables
  >(kycReasonLogMutation)

  const handleKycStatusUpdate = async (reason: string) => {
    if (!reason) {
      notify('Please fill in reason field')
      setLoading(false)
      return
    }

    setLoading(true)
    try {
      const res = await executeKycStatusUpdateMutation({
        variables: {
          identificationDatumId: data.id.toString(),
          // For now we give the rejected but in the future we might need to give more/different reasons
          givenStatus: 'MANUAL_REJECTED',
          reason: reason,
        },
      })

      const reasonLog = res?.data.kycReasonLog.string ?? ''
      if (reasonLog) {
        notify(`Rejected KYC for ${data.id} with reason: ${reason}`)
        setReason('')
      } else {
        notify('Failed to reject KYC')
      }
    } catch (error) {
      notify(error.message || 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Paper>
        <Container className={classes.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleKycStatusUpdate(reason)}
          >
            {loading ? 'Processing...' : 'Done'}
          </Button>
        </Container>
      </Paper>
    </Container>
  )
}

export default KycStatusUpdateForm
