import { ResponsiveCalendar } from '@nivo/calendar'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'

import {
  contentInsightsInteractions,
  contentInsightsInteractionsVariables,
} from '../../../__generated__/contentInsightsInteractions'
import { ContentInsightsInteractions } from '../../../graphQL/content_insights'

export const ContentInsightsInteractionHeatMap = ({
  recordId,
}: {
  recordId: string
}) => {
  const trendsDataInteractionsResult = useQuery<
    contentInsightsInteractions,
    contentInsightsInteractionsVariables
  >(ContentInsightsInteractions, {
    variables: {
      id: recordId,
      dateInterval: { days: 1 },
    },
  })

  const trendsDataInteractions =
    trendsDataInteractionsResult.data?.qry?.nodes.map((item: any) => {
      return {
        day: item.date.split('T')[0],
        value: item.count,
      }
    }) || []

  return (
    <ErrorBoundary fallbackRender={() => <div>Error rendering chart</div>}>
      {trendsDataInteractions.length > 0 ? (
        <ResponsiveCalendar
          data={trendsDataInteractions}
          from={trendsDataInteractions[0].day}
          to={
            trendsDataInteractions[trendsDataInteractions.length - 1 || 0].day
          }
          emptyColor="#eeeeee"
          colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
          margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'row',
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: 'right-to-left',
            },
          ]}
        />
      ) : null}
    </ErrorBoundary>
  )
}
