import gql from 'graphql-tag'

export const kycReasonLogMutation = gql`
  mutation KycReasonLog(
    $identificationDatumId: String!
    $givenStatus: String!
    $reason: String!
  ) {
    kycReasonLog(
      input: {
        identificationDatumId: $identificationDatumId
        givenStatus: $givenStatus
        reason: $reason
      }
    ) {
      string
    }
  }
`
