import gql from 'graphql-tag'

export const ContentInsightsMovieInformation = gql`
  query cmsMovieByIdContentInsights($id: Guid!) {
    qry: cmsMovieById(id: $id) {
      id
      title
      widescreenImage {
        masterUrl
      }
      coverImage {
        masterUrl
      }
      createdDate
    }
  }
`

export const ContentInsightsPlayEvents = gql`
  query contentInsightsPlayEvents($id: String!, $dateInterval: IntervalInput) {
    qry: contentInsightsPlayEvents(
      contentId: $id
      dateInterval: $dateInterval
    ) {
      nodes {
        date
        count
      }
    }
  }
`

export const ContentInsightsWatchduration = gql`
  query contentInsightsWatchduration(
    $id: String!
    $dateInterval: IntervalInput
    $dateFrom: Datetime
  ) {
    qry: contentInsightsWatchduration(
      contentId: $id
      dateInterval: $dateInterval
      dateFrom: $dateFrom
    ) {
      nodes {
        date
        count
      }
    }
  }
`

export const ContentInsightsCompletionRates = gql`
  query contentInsightsCompletionRates($id: String!) {
    qry: contentInsightsCompletionRates(contentId: $id) {
      nodes {
        label
        count
      }
    }
  }
`

export const ContentInsightsPlayCount = gql`
  query contentInsightsPlayCount($id: String!) {
    playCount: contentInsightsPlayCount(contentId: $id)
  }
`

export const ContentInsightsWishlistCount = gql`
  query contentInsightsWishlistCount($id: String!) {
    wishlistCount: contentInsightsWishlistCount(contentId: $id)
  }
`

export const ContentInsightsDownloadCount = gql`
  query contentInsightsDownloadCount($id: String!) {
    downloadCount: contentInsightsDownloadCount(contentId: $id)
  }
`

export const ContentInsightsPlayEventsByPlatform = gql`
  query contentInsightsPlayEventsByPlatform($id: String!) {
    qry: contentInsightsPlayEventsByPlatform(contentId: $id) {
      nodes {
        platform
        count
      }
    }
  }
`

export const ContentInsightsInteractions = gql`
  query contentInsightsInteractions(
    $id: String!
    $dateInterval: IntervalInput
  ) {
    qry: contentInsightsInteractions(
      contentId: $id
      dateInterval: $dateInterval
    ) {
      nodes {
        date
        count
      }
    }
  }
`

export const ContentInsightsDownloadEvents = gql`
  query contentInsightsDownloadEvents(
    $id: String!
    $dateInterval: IntervalInput
  ) {
    qry: contentInsightsDownloadEvents(
      contentId: $id
      dateInterval: $dateInterval
    ) {
      nodes {
        date
        count
      }
    }
  }
`

export const ContentInsightsWishlistEntries = gql`
  query contentInsightsWishlistEntries(
    $id: String!
    $dateInterval: IntervalInput
  ) {
    qry: contentInsightsWishlistEntries(
      contentId: $id
      dateInterval: $dateInterval
    ) {
      nodes {
        date
        count
      }
    }
  }
`
