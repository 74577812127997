import { ComponentType } from 'react'
import { FieldProps } from 'react-admin'
import { OverviewMovie } from '../../apps/contentInsights/OverviewMovie'
import { GenerateVoucherFields } from './AdvancedFields/GenerateVoucherFields'
import { RelatedTableAllocationFields } from './AdvancedFields/RelatedTableAllocationFields'
import { Assets } from './Assets'
import { CategoriesFieldsWithExistingRecord } from './CategoriesFields'
import Checks from './Checks'
import { CommentaryField } from './CommentaryField'
import { IsActiveFields } from './IsActiveFields'
import { AudioInfoFields } from './MediaInfoAudioFields'
import { MediaInfoFields } from './MediaInfoFields'
import { VideoInfoFields } from './MediaInfoVideoFields'
import { MetadataFields } from './MetadataFields'
import { ObjectFields } from './ObjectFields'
import { SortableListFieldsCategories } from './SortableListFieldsCategories'
import { SortableListFieldsMovies } from './SortableListFieldsMovies'
import { UpdateRatingFields } from './UpdateRatingFields'
import { Videoplayer } from './VideoplayerField'

// INFO
// define in TypeDisplaySetting if it should appears in list, show or edit
// if show:
// define in TabLayoutSettings in which Tabs it should appears

export const CUSTOM_FIELD_COMPONENTS: {
  [key in string]?: ComponentType<FieldProps>
} = {
  JSON_EXTRACT_METADATA: MetadataFields,
  //@ts-ignore
  JSON_EXTRACT_METADATA_ASSETS: Assets,
  //@ts-ignore
  JSON_EXTRACT_METADATA_CHECKS: Checks,
  JSON_EXTRACT_MEDIAINFO: MediaInfoFields,
  JSON_EXTRACT_MEDIAINFO_VIDEO: VideoInfoFields,
  JSON_EXTRACT_MEDIAINFO_AUDIO: AudioInfoFields,
  VIDEOPLAYER: Videoplayer,
  COMMENT: CommentaryField,
  CATEGORIES: CategoriesFieldsWithExistingRecord,
  VIDEOSOURCE: ObjectFields,
  SORTABLE_CATEGORY_LIST: SortableListFieldsCategories,
  SORTABLE_MOVIE_LIST: SortableListFieldsMovies,
  OVERVIEW_MOVIE: OverviewMovie,
  GENERATE_VOUCHERS: GenerateVoucherFields,
  UPDATE_RATING: UpdateRatingFields,
  ISACTIVE: IsActiveFields,

  CAT_ALLOCATION: (props) =>
    RelatedTableAllocationFields(props, {
      connectionTableName: 'CmsMovieContentCategory',
      relatedRessourceNames: ['CmsMovie', 'CmsSery'],
    }),
  PROD_ALLOCATION: (props) =>
    RelatedTableAllocationFields(props, {
      connectionTableName: 'VoucherCampaignPaymentProduct',
      relatedRessourceNames: ['SubscriptionProduct'],
    }),
}
