import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import GradeIcon from '@material-ui/icons/Grade'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { motion } from 'framer-motion'
import React from 'react'
import { FieldProps } from 'react-admin'
import { useQuery } from 'react-apollo-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import {
  cmsMovieByIdContentInsights,
  cmsMovieByIdContentInsightsVariables,
} from '../../__generated__/cmsMovieByIdContentInsights'
import {
  contentInsightsDownloadCount,
  contentInsightsDownloadCountVariables,
} from '../../__generated__/contentInsightsDownloadCount'
import {
  contentInsightsPlayCount,
  contentInsightsPlayCountVariables,
} from '../../__generated__/contentInsightsPlayCount'
import {
  contentInsightsWishlistCount,
  contentInsightsWishlistCountVariables,
} from '../../__generated__/contentInsightsWishlistCount'
import {
  ContentInsightsDownloadCount,
  ContentInsightsMovieInformation,
  ContentInsightsPlayCount,
  ContentInsightsWishlistCount,
} from '../../graphQL/content_insights'
import { smplColors } from '../../layout/themes'
import { ContentInsightsCompletionRateFunnel } from './components/completionRateFunnel'
import { ContentInsightsInteractionHeatMap } from './components/interactionHeatMap'
import { ContentInsightsPlayEventsByPlatformChart } from './components/playsEventsByPlatformChart'
import { ContentInsightsTrendDataChart } from './components/trendDataChart'
import { ContentInsightsWatchdurationChart } from './components/watchdurationChart'

const useStyles = makeStyles({
  titleContainer: {
    width: 'calc(100% - 1rem)',
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 10,
  },
  widescreenTitle: {
    width: '100%',
    height: '400px',
    backgroundSize: 'cover!important',
    backgroundPosition: 'center center!important',
    borderRadius: '1rem',
  },
  overlayWrapper: {
    width: '100%',
    height: '100%',
    background: '#00000095',
    borderRadius: '1rem',
    display: 'grid',
    gridTemplateColumns: '400px 1fr',
    gridTemplateRows: '1fr 1fr 80px',
    gridTemplateAreas: `
                        "cover name"
                        "cover name"
                        "cover status"
    `,
  },
  titleCover: {
    gridArea: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '60%',
      borderRadius: '0.25rem',
      transform: 'rotate(-2deg)',
      boxShadow: `0.1rem 0.1rem 0rem ${smplColors.secondary.main},
      0.2rem 0.2rem 0rem ${smplColors.secondary.main},
      0.3rem 0.3rem 0rem ${smplColors.secondary.main},
      0.4rem 0.4rem 0rem ${smplColors.secondary.main},
      1rem 1rem 2rem #00000095`,
    },
  },
  titleTitle: {
    gridArea: 'name',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'white',
    fontSize: '2.5rem',
  },
  statusWrap: {
    gridArea: 'status',
    display: 'flex',
    background: `${smplColors.primary.main}`,
    borderRadius: '1rem 0 1rem 0',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    borderRight: '1px solid #ffffff50',
    color: 'white',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    '& svg': {
      transform: 'scale(1.2)',
      marginTop: '1rem',
    },
  },
  statsContainer: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingRight: '1rem',
  },
  licenseStatWrapper: {
    width: '100%',
    height: '80px',
    background: `white`,
    borderRadius: '1rem',
    margin: '1rem',
    display: 'flex',
    boxShadow: '0.5rem 0.5rem 1rem #00000020',
  },
  chartStatWrapper: {
    height: '300px',
    flexGrow: 1,
    background: `white`,
    borderRadius: '1rem',
    margin: '1rem',
    boxShadow: '0.5rem 0.5rem 1rem #00000020',
  },
  chartStatTitle: {
    paddingTop: '1rem',
    margin: 'auto',
    textAlign: 'center',
  },
  chartWrapper: {
    height: '275px',
  },
})

const OverviewMovieImpl = (props: FieldProps): JSX.Element => {
  const classes = useStyles()

  const { record } = props

  const contentInformation = useQuery<
    cmsMovieByIdContentInsights,
    cmsMovieByIdContentInsightsVariables
  >(ContentInsightsMovieInformation, {
    variables: {
      id: record.id.toString(),
    },
  })

  const playCount = useQuery<
    contentInsightsPlayCount,
    contentInsightsPlayCountVariables
  >(ContentInsightsPlayCount, {
    variables: {
      id: record.id.toString(),
    },
  })
  const wishlistCount = useQuery<
    contentInsightsWishlistCount,
    contentInsightsWishlistCountVariables
  >(ContentInsightsWishlistCount, {
    variables: {
      id: record.id.toString(),
    },
  })
  const downloadCount = useQuery<
    contentInsightsDownloadCount,
    contentInsightsDownloadCountVariables
  >(ContentInsightsDownloadCount, {
    variables: {
      id: record.id.toString(),
    },
  })

  return (
    <>
      <div className={classes.titleContainer}>
        <motion.div
          initial={{ y: -500 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.15, duration: 2, ease: 'anticipate' }}
          className={classes.widescreenTitle}
          style={{
            background: `url(${contentInformation.data?.qry?.widescreenImage?.masterUrl})`,
          }}
        >
          <div className={classes.overlayWrapper}>
            <motion.div
              initial={{ y: -500 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.25, duration: 2, ease: 'anticipate' }}
              className={classes.titleCover}
            >
              <img src={contentInformation.data?.qry?.coverImage?.masterUrl} />
            </motion.div>
            <motion.div
              initial={{ y: -500 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.45, duration: 2, ease: 'anticipate' }}
              className={classes.titleTitle}
            >
              {contentInformation.data?.qry?.title}
            </motion.div>
            <motion.div
              initial={{ x: 500 }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, duration: 2, ease: 'anticipate' }}
              className={classes.statusWrap}
            >
              <motion.div
                initial={{ x: 500 }}
                animate={{ x: 0 }}
                transition={{ delay: 1.1, duration: 2, ease: 'anticipate' }}
                title="Number of content plays"
                className={classes.status}
              >
                <PlayCircleOutlineIcon />
                <p>{playCount?.data?.playCount}</p>
              </motion.div>

              <motion.div
                initial={{ x: 500 }}
                animate={{ x: 0 }}
                transition={{
                  delay: 1.2,
                  duration: 2,
                  ease: 'anticipate',
                }}
                title="Number of wishlist entries"
                className={classes.status}
              >
                <GradeIcon />
                <p>{wishlistCount?.data?.wishlistCount}</p>
              </motion.div>

              <motion.div
                initial={{ x: 500 }}
                animate={{ x: 0 }}
                transition={{
                  delay: 1.3,
                  duration: 2,
                  ease: 'anticipate',
                }}
                title="Number of downloads"
                className={classes.status}
              >
                <CloudDownloadIcon />
                <p>{downloadCount?.data?.downloadCount}</p>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 2, ease: 'anticipate' }}
        className={classes.statsContainer}
      >
        {/* License blocks start */}
        {/* TODO license display overview */}
        {/* <div className={classes.licenseStatWrapper}>
          <ReceiptIcon
            style={{
              color: `${smplColors.success.main}`,
              paddingTop: '1.3rem',
              paddingLeft: '1.2rem',
              transform: 'scale(1.5)',
            }}
          />
          <h3 style={{ paddingTop: '0.7rem', paddingLeft: '2rem' }}>
            License One
          </h3>
          <h5
            style={{
              paddingTop: '0.7rem',
              paddingLeft: '2rem',
              color: `${smplColors.success.main}`,
            }}
          >
            ACTIVE
          </h5>
          <h6
            style={{
              paddingTop: '0.6rem',
              paddingLeft: '2rem',
            }}
          >
            253 days left ( until 02.02.2024 )
          </h6>
        </div>
        <div className={classes.licenseStatWrapper}>
          <ReceiptIcon
            style={{
              color: `${smplColors.success.main}`,
              paddingTop: '1.3rem',
              paddingLeft: '1.2rem',
              transform: 'scale(1.5)',
            }}
          />
          <h3 style={{ paddingTop: '0.7rem', paddingLeft: '2rem' }}>
            License Two
          </h3>
          <h5
            style={{
              paddingTop: '0.7rem',
              paddingLeft: '2rem',
              color: `${smplColors.success.main}`,
            }}
          >
            ACTIVE
          </h5>
          <h6
            style={{
              paddingTop: '0.6rem',
              paddingLeft: '2rem',
            }}
          >
            150 days left ( until 23.12.2023 )
          </h6>
        </div>
        <div className={classes.licenseStatWrapper}>
          <ReceiptIcon
            style={{
              color: `${smplColors.error.main}`,
              paddingTop: '1.3rem',
              paddingLeft: '1.2rem',
              transform: 'scale(1.5)',
            }}
          />
          <h3 style={{ paddingTop: '0.7rem', paddingLeft: '2rem' }}>
            License Three
          </h3>
          <h5
            style={{
              paddingTop: '0.7rem',
              paddingLeft: '2rem',
              color: `${smplColors.error.main}`,
            }}
          >
            INACTIVE
          </h5>
          <h6
            style={{
              paddingTop: '0.6rem',
              paddingLeft: '2rem',
            }}
          >
            0 days left
          </h6>
        </div> */}
        {/* License blocks end */}
        {/* Trends Data LineChart */}
        <div className={classes.chartStatWrapper} style={{ width: 550 }}>
          <div className={classes.chartWrapper}>
            <ContentInsightsTrendDataChart recordId={record.id.toString()} />
          </div>
        </div>
        <ErrorBoundary fallbackRender={() => <div>Error rendering 2</div>}>
          <div className={classes.chartStatWrapper} style={{ width: 400 }}>
            <div className={classes.chartStatTitle}>
              <span>Plays by Platform</span>
            </div>
            <div className={classes.chartWrapper}>
              <ContentInsightsPlayEventsByPlatformChart
                recordId={record.id.toString()}
              />
            </div>
          </div>
        </ErrorBoundary>
        <ErrorBoundary fallbackRender={() => <div>Error rendering 3</div>}>
          <div className={classes.chartStatWrapper} style={{ width: 250 }}>
            <div className={classes.chartStatTitle}>
              <span>Completion Rate Funnel</span>
            </div>
            <div className={classes.chartWrapper}>
              <ContentInsightsCompletionRateFunnel
                recordId={record.id.toString()}
              />
            </div>
          </div>
        </ErrorBoundary>
        <ErrorBoundary fallbackRender={() => <div>Error rendering 4</div>}>
          <div className={classes.chartStatWrapper} style={{ width: 750 }}>
            <div className={classes.chartStatTitle}>
              <span>Watchduration in Hours</span>
            </div>
            <div className={classes.chartWrapper}>
              <ContentInsightsWatchdurationChart
                recordId={record.id.toString()}
                chartStartDate={
                  new Date(contentInformation.data?.qry?.createdDate)
                }
              />
            </div>
          </div>
        </ErrorBoundary>
        <div className={classes.chartStatWrapper} style={{ width: 550 }}>
          <div className={classes.chartStatTitle}>
            <span>Interaction HeatMap</span>
          </div>
          <div className={classes.chartWrapper}>
            <ContentInsightsInteractionHeatMap
              recordId={record.id.toString()}
            />
          </div>
        </div>
      </motion.div>
    </>
  )
}

export const OverviewMovie = React.memo(OverviewMovieImpl)
