import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import { getToken } from '../../../authProvider'
import { headersForApi } from '../../../commons/flexgold/constants'
import { SMPL_FLEXGOLD_API_BASEURL } from '../../../lib/config'
import KycStatusUpdateForm from '../../customForm/KycStatusUpdateForm'
import { ToggleAMLTagsForm } from '../../customForm/ToggleAMLTagsForm'
import type { SplitButtonProps } from '../../SplitActionButton'

export const identificationDatumSplitActions: SplitButtonProps['optionFn'] = (
  props
) => {
  const { data, notify, refresh, confirmClasses } = props

  const downloadKycForm = (formType: 'a' | 'b' | 'k' | 'l') => async () => {
    try {
      const response = await fetch(
        new URL(
          `/admin/amlForm/identificationDatum/${data.id}/form/${formType}`,
          SMPL_FLEXGOLD_API_BASEURL
        ).toString(),
        {
          method: 'GET',
          headers: {
            ...headersForApi,
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )

      if (!response.ok) {
        notify(`Could not download form ${formType}`, 'error')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const formElement = document.createElement('a')
      formElement.style.display = 'none'
      formElement.href = url
      formElement.download = `form-${formType}-${data.id}.pdf`
      document.body.appendChild(formElement)
      formElement.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(error)

      notify(`Could not download form ${formType}`, 'error')
    }
  }

  return [
    {
      actionType: 'withConfirmButton',
      btnClassName: '',
      icon: <FolderSharedIcon />,
      record: data,
      confirmTitle: 'Reject KYC',
      confirmContent: <KycStatusUpdateForm data={data} />,
      confirm: 'Done',
      label: 'Reject KYC',
      color: 'primary',
      size: 'medium',
      disableCancelBtn: true,
      onConfirm: () => refresh(true),
      confirmClasses: confirmClasses,
    },
    {
      actionType: 'withConfirmButton',
      btnClassName: '',
      icon: <AccountBalanceIcon />,
      record: data,
      confirmTitle: 'Toggle AML Tags',
      confirmContent: <ToggleAMLTagsForm data={data} />,
      confirm: 'Done',
      label: 'AML tags',
      color: 'primary',
      size: 'medium',
      disableCancelBtn: true,
      onConfirm: () => refresh(true),
      confirmClasses: confirmClasses,
    },
    ...(data.type === 'personal'
      ? ([
          {
            startIcon: <FolderSharedIcon />,
            label: 'Download Form A',
            onClick: downloadKycForm('a'),
          },
          {
            startIcon: <FolderSharedIcon />,
            label: 'Download Form B',
            onClick: downloadKycForm('b'),
          },
        ] as const)
      : []),
    ...(typeof data.type === 'string' &&
    ['company', 'broker'].includes(data.type)
      ? [
          {
            startIcon: <FolderSharedIcon />,
            label: 'Download Form K',
            onClick: downloadKycForm('k'),
          },
          {
            startIcon: <FolderSharedIcon />,
            label: 'Download Form L',
            onClick: downloadKycForm('l'),
          },
        ]
      : []),
  ]
}
